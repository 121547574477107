.icon-bar {
  z-index: 900;
  /* always on top */
  position: fixed;
  left: 0;
  top: 0;
  width: 60px;
  /* Initial width */
  height: 100vh;
  background-color: #2a375e;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  overflow-y: auto;
  overflow-x: hidden;
  transition: width 0.3s ease;
  /* Add transition for smooth width change */
}

.submenu-container {
  position: absolute;
  left: 0;
  top: 60px;
  width: 195px;
  max-height: calc(100vh - 60px);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-left: 12px;
}

.icon-bar.expanded {
  width: 195px;
  /* Expanded width */
  align-items: flex-start;
  /* Left-align icons */
}

.menu-item-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-right: 20px;
}

.icon-container {
  display: flex;
  /* Use flexbox for icon and text alignment */
  align-items: center;
  /* Align items vertically */
  position: relative;
  margin-top: 15px;
}

.sidebar-menu-item {
  width: 100%;
  display: flex;
  /* Use flexbox for icon and text alignment */
  flex-direction: row;
  color: white;
  padding: 3px;
}

.sidebar-menu-item:hover {
  background: #cccfd4 !important;
  border-radius: 0.4em;
  opacity: 1;
  color: #293147;
  justify-content: flex-start !important;
}

.icon {
  width: 22px;
  height: 22px;
}

.menu-title {
  font-size: 1em;
  font-weight: bolder;
  display: none; /* Initially hidden */
  margin-left: 15px; /* Add space between icon and text */
}

.icon-bar.expanded .menu-title {
  display: inline-block; /* Display when expanded */
}

.arrow {
  margin-left: auto; 
  cursor: pointer;
}

.submenu-arrow {
  margin-left: 12px; 
  font-size: smaller;
  cursor: pointer;
  padding-right: 20px;
}

.sub-menu {
  display: flex;
  flex-direction: column; /* Display submenu items vertically */
  max-width: 185px;
}

.submenu-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
}

.submenu-title-container:hover {
  background: #cccfd4 !important;
  border-radius: 0.4em;
  opacity: 1;
  color: #293147;
}

.sub-menu-item {
  display: flex;
  flex-direction: column;
  padding: 3px;
  margin-left: 40px;
}

.submenu-title {
  font-size: 1.1em;
  padding-left: 10px;
  width: 100%;
}

.sub-sub-menu {
  display: flex;
  flex-direction: column; /* Display submenu items vertically */
  max-width: 185px;
  margin-left: 10px;
}

.sub-submenu-title {
  font-size: 0.9em;
  color: white;
  padding: 3px;
  padding-left: 10px;
}

.sub-submenu-title:hover {
  background: #cccfd4 !important;
  border-radius: 0.4em;
  opacity: 1;
  color: #293147;
}


.submenu-logo {
  width: 20px;
  height: 20px;
  margin-right: 6px;
  vertical-align: middle;
}

.submenu-title-container {
  display: flex;
  align-items: center;
}