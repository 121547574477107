.page-switcher-container {
  display: flex;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 20px;
}

.page-switcher {
  display: flex;
  max-width: 350px;
  justify-content: center;
  background: #2a375e;
  border-radius: 10px;
}

.page-switcher-button {
  display: flex;
  align-items: center;
  /* Vertically center the text */
  justify-content: center;
  padding: 0.25rem;
  width: 8rem;
  height: fit-content;
  text-decoration: none;
  color: white;
  font-size: 1.25rem;
}

.page-switcher-button.active {
  background-color: #535dad;
  color: white;
}

.page-switcher-button.active:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.page-switcher-button.active:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.page-switcher-button:hover {
  background-color: #cccfd4;
  color: #293147;
}

.page-switcher-button:hover:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.page-switcher-button:hover:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.page-switcher-buttonselected {
  background-color: lightblue;
}